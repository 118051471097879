

.courses-wrap {
    text-align: center;
    background-image: url("../../../assets/artsandscience\ course\ background.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .courses-container {
      max-width: 80%; // Adjusted for responsiveness
      background-color: #ffffff;
      padding: 20px;
      margin: 0 auto;
      text-align: left;
      padding: 40px;
      z-index: 1; // Ensure it appears above the background

      h2 {
        // margin-top: 8%;
        font-size: 3rem;
        font-weight: bold;
        background-color:#650400 !important;
        color: white;
        display: inline-block;
        padding: 10px;
        width: 100%;
        text-align: center;
      }

      p {
        margin-top: 5%;
        padding: 20px;
        font-size: 2rem;
        line-height: 1.5;
        color: #333;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 20px;
        margin: 10px 0;

        @media (max-width: 768px) {
          font-size: 1.5rem;
        }
      }
      .horizontal-dropdowns {
        display: flex;
        justify-content: center;
        gap: 20px;
        
      }
      .dropdown {
        .nav-link {
          font-weight: 500;
          text-decoration: none;
          font-size: 1.5rem;
          text-align: center;
          // display: inline-block;
          padding: 10px 15px; /* Adjust padding as needed */
          margin: 0 auto; /* Center the button horizontally */
          background-color: #042243f7; /* Button background color */
          color: #fff; /* Text color */
          border: none; /* Remove any default border */
          border-radius: 5px; /* Rounded corners */
          cursor: pointer; /* Show pointer cursor on hover */
          transition: background-color 0.3s;
          @media (max-width: 768px) {
            font-size: 1.5rem;
          }
        }
      }

      li {
        list-style: none;
      }
      .centered-dropdown-menu {
        background-color: #ffffff;
        text-align: center;
        width: 40%; // Adjusted width
        max-height: 300px;
        overflow-y: auto;
        position: absolute;
        top: calc(50% + 50px); // Opens below the button and centered vertically
        left: 50%; // Centered horizontally
        transform: translateX(-50%); // Center the menu horizontally
        display: none;
        z-index: 100; // Ensure it appears above other content

        a {
          color: black;
          padding: 10px 15px;
          text-decoration: none;
          display: block;
          transition: background-color 0.3s;

          &:hover {
            // background-color: #6b2003;
            // color: white;
          }
        }
      }

      &.open .centered-dropdown-menu {
        display: block;
      }

      .selected-component {
        // background-color: #ffffff;
        padding: 20px;
        z-index: 200; // Ensure it appears above the dropdown menu
        position: relative;

        button {
          background-color: #6b2003;
          color: white;
          border: none;
          padding: 10px 20px;
          font-size: 1.5rem;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 10px;

          &:hover {
            background-color: #542000;
          }
        }
      }
    }
  }
