.marquee-display {
    text-align: center;
    margin: 0;
  border: none !important;
   
a{
  text-decoration: none;
    color:#fff;
    font-size: 17px;
    font-weight: 300;
}
}
      
    
   