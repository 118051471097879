// SportsGames.scss

.sports-games-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;
    padding-top: 100px;

    .sports-games-view {
      display: flex;
      gap: 20px; /* Spacing between the image and description */
  
      .sports-image-container234 {
        img {
          max-width: 80%; /* Ensure the image does not exceed its container width */
          height: auto; /* Maintain aspect ratio */
          border-radius: 8px; /* Add border radius for rounded corners */
        }
      }
  
      .description-container {
        .sports-title {
          font-size: 24px;
          color: #333;
          margin-bottom: 10px;
        }
  
        .sports-text {
          font-size: 16px;
          color: #666;
          line-height: 1.6;
        }
  
        .google-form-link {
          color: blue;
          text-decoration: underline;
  
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  
    .gallery {
      width: 100%;
  
      h2 {
        font-size: 24px;
        color: #333;
        margin-bottom: 20px;
      }
    }
  
    // Media query for smaller screens
    @media screen and (max-width: 768px) {
      .sports-games-view {
       
        flex-direction: column; /* Display image and description in a column layout */
         justify-content: flex-start;
         padding-left: 2%;
      }
    }
  }
  