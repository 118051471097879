.programs-container {
    text-align: center;
    background-color: hsl(0, 0%, 96%); /* Light gray background */
    max-width: 80%;
    margin: 0 auto;
    padding: 40px;
    z-index: 1;
  
    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      background-color: #650400 !important;
      color: white;
      display: inline-block;
      padding: 10px 20px;
      border-radius: 8px;
      text-align: center;
      margin-top: 2rem;
    }
  
    p {
      margin-top: 1rem;
      padding: 20px;
      font-size: 1.6rem;
      line-height: 1.6;
      color: #333;
    }
  
    h2 {
      font-weight: 600;
      margin-top: 2rem;
      font-size: 2rem;
      color: #650400 !important;
    }
  
    ul {
      list-style-type: disc;
      padding-left: 20px;
  
      li {
        font-size: 1.6rem;
        color: #555;
        margin-top: 1rem;
  
        span {
          font-weight: bold;
          color: #650400 !important;
        }
      }
    }
  
    img {
      width: 100%;
      max-width: 600px;
      display: block;
      margin: 2rem auto;
    }
  
    @media (max-width: 768px) {
      /* Add responsive styles for screens up to 768px wide */
      h1 {
        font-size: 2rem;
        margin-top: 1.5rem;
      }
  
      p {
        font-size: 1.4rem;
      }
  
      h2 {
        font-size: 1.8rem;
        margin-top: 1.5rem;
      }
    }
  }
  