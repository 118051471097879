// collegeGroup.scss
.college-grp1 {
  margin-top: 10%;
}

.colleges-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 11%;
  }
}

.college-item {
  padding: 30px;
  text-align: center;

  img {
    padding: 0;
    display: block;
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
    width: 145px;
    height: 137px;
    border-radius: 8px;
    filter: brightness(120%) contrast(130%);
  }

  img:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: scale(1.2);
  }

  .college-label {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin-top: 16px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  p {
    color: #fff;
    font-size: 16px;
    margin-top: 8px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

#clg1:hover {
  background-color: rgb(204, 78, 78); /* Red background for Arts & Science */
}

#clg2:hover {
  background-color: #ffffffda; /* White background for Nursing */
}

#clg3:hover {
  background-color: rgba(74, 180, 74, 0.566); /* Green background for Pharmacy */
}

#clg4:hover {
  background-color: rgb(234, 214, 99); /* Gold background for Education */
}

#clg5:hover {
  background-color: rgba(45, 151, 212, 0.311); /* Blue background for Vellamal Education */
}
