.campus-container {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  padding-top: 100px;


.facility-card {
  width: 80%;
  margin-bottom: 50px;
}

.facility-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.facility-content {
  display: flex;
  align-items: flex-start;
}

.facility-image-container {
  width: 300px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  margin-right: 20px;
}

.facility-image {
  width: 100%;
  height: 100%;
  margin-right: 20px;
  cursor: pointer;
}

.facility-description {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 20px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  padding: 15px;
  margin-top: -30px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

@media (max-width: 768px) {
  .campus-container {
    padding: 20px;
  }
  .facility-card {
    width: 100%; /* Occupy full width */
    margin-bottom: 50px; /* Adjust margin for spacing */
    display: block; /* Render one below the other */
  }

  .facility-image-container {
    width: 100%; /* Occupy full width */
    height: 200px; /* Set a fixed height */
    margin-bottom: 20px; /* Adjust margin for spacing */
  }

  .facility-details {
    // text-align: center;
  }

  .facility-title {
    font-size: 24px; /* Increase font size */
    color: #333;
    text-align: center; /* Center text */
    margin-bottom: 10px;
  }

  .facility-description {
    font-size: 14px;
  }
}
.facilities-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.facility-card {
  margin: 10px;
  cursor: pointer;
}

.facility-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}


.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  padding: 15px;
  margin-top: -30px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
}