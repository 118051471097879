.home-one {
  position: relative; /* Ensure z-index works correctly */
  overflow: hidden; /* Ensure the blur effect doesn't leak outside the container */
}

.background-container {
  background-image: url("../../assets/backgrounds/bg3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind other content */
  filter: blur(5px); /* Apply blur effect */
}

/* Add this to your home.scss file */


