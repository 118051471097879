.chairman-wrapper {
  .chairman-1 {
    margin-top: 10%;
    display: flex;
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */
    
    .vertical-line2 {
      margin-left: 2%;
      margin-top: 3%;
      width: 70px;
      height: 300px;
      background-color: #f7b322;
    }
    
    .vertical-line3 {
      margin-top: 5%;
      width: 10px;
      height: 250px;
      background-color: #fff;
    }
    
    .chairman-logo {
      margin-left: 1%; /* Adjust margin for image */
      margin-top: 2%;
      img {
        width: 280px; /* Reduce width of the image for smaller screens */
        height: 340px; /* Maintain aspect ratio */
        flex-shrink: 0;
        border-radius: 10%;
      }
    }
    
    .chairmanMsg {
      margin-top: 20px; /* Add margin to separate from the image */
      text-align: center; /* Center text */
    
      .chairman-title {
        h3 {
          margin-left: 5%;
          color: #fff !important;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          font-size: 35px;
          font-style: normal;
          font-weight: 600;
          animation: bubbleAnimation 2s ease-in-out;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .chairman-1 {
      flex-direction: column; /* Change to column layout for smaller screens */
      justify-content: center; /* Center items vertically */
      
      .vertical-line2 {
        margin-left: auto; /* Align to the right */
        margin-right: auto; /* Align to the left */
        display: none;
      }
      
      .vertical-line3 {
        margin-left: auto; /* Align to the right */
        margin-right: auto; /* Align to the left */
        display: none;

      }
      
      .chairman-logo {
        width: 200px; /* Reduce width of the image for smaller screens */
        margin-left: 14%; /* Align image to the right */
        margin-right: auto; /* Align image to the left */
      // justify-content: center; /* Center items vertically */


      }
      
      .chairmanMsg {
        margin-top: 20px; /* Add margin to separate from the image */
      }
    }
  }
}
