/* VellamalNav.scss */

.NavBarVellamal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #87a9d1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1),
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 5px 0; /* Adjusted padding */
  background-image: url("/assets/Vellamal/vellamal-nav.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  .navbarVellamal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px; /* Adjusted padding */

    .logo-container {
      display: flex;
      align-items: center;
      margin-right: 10px; /* Adjusted margin */

      .logo {
        img {
          max-height: 80px; /* Decreased image size */
          max-width: 80px; /* Decreased image size */
        }
      }

      .logo-txt {
        h1 {
          font-size: 1.8rem; /* Slightly smaller font size */
          margin-left: 10px;
          margin-top: 2px;
          color: white;
          font-weight: 700;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        }
      }
    }

    .menu {
      list-style: none;
      color: white;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      gap: 15px; /* Increased gap between menu items for better mobile view */
      display: flex;
      flex-direction: row;

      a {
        text-decoration: none;
        color: white;
        font-weight: 500;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        padding: 8px 12px; /* Adjusted padding for better mobile view */
        white-space: nowrap;
        transition: all 0.3s ease;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          background-color: transparent;
          border-bottom: 3px solid #dcd1cd;
          padding-bottom: 5px;
        }

        @media screen and (max-width: 768px) {
          font-size: 1rem;
          padding: 5px 8px; /* Adjusted padding for mobile view */
        }
      }

      ul {
        display: flex;
      }

      .dropdown {
        position: relative;

        &:hover .nav-link.dropdown-toggle {
          background-color: transparent;

          .dropdown-menu {
            display: block;
          }
        }

        .nav-link.dropdown-toggle {
          background-color: transparent;
          color: white;
          border: none;
          text-decoration: none;
          cursor: pointer;
          font-weight: 500;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          white-space: nowrap;

          &:hover {
            background-color: transparent;
          }

          &.active {
            background-color: transparent;
            border-bottom: 3px solid #dcd1cd;
            padding-bottom: 5px;
          }

          .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: auto;
            display: none;
            background-color: white;
            border: none;
            border-radius: 0;
            box-shadow: none;
            min-width: 160px;
            z-index: 1;
            color: black;

            a {
              color: black;
              top: 0;
              padding: 10px 15px;
              text-decoration: none;
              display: block;
              transition: background-color 0.3s;

              .dropdown-menu-2 {
                position: absolute;
                top: 0;
                left: -70%;
                display: none;
                background-color: white;
                border: none;
                border-radius: 0;
                box-shadow: none;
                min-width: 160px;
                z-index: 1;
                color: black;
                list-style: none;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 769px) {
    .menu a {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    .navbarVellamal {
      flex-direction: column;
      align-items: flex-start;

      .logo-container {
        .logo-txt {
          h1 {
            margin-top: 10px;
            font-size: 1.5rem;
          }
        }
      }
      .menu {
        margin-top: 20px;
        margin-left: 20px;
      }

      .menu a.active {
        background-color: transparent;
        border-bottom: 3px solid #c44b26;
        padding-bottom: 5px;
      }
    }
  }
}
