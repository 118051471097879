// Define variables
$milestone-bg-color: rgba(232, 209, 209, 0.46);
$milestone-text-color: rgb(33, 20, 20);

// Import fonts
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@600&family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

// Main wrapper
.mileStone-wrapper {
  margin-bottom: 5vw; // Use vw unit for responsive margin
  padding-top: 100px;

  // Milestone title
  .mileStone-title {
    margin-bottom: 5vw; // Use vw unit for responsive margin
    text-align: center;

    h2 {
      margin-left: auto; // Align center
      margin-right: auto; // Align center
      font-weight: 600;
      color: $milestone-text-color;
      text-align: center;
      text-decoration: wavy;
      padding: 0;
      font-family: "Arial", sans-serif;

      span {
        display: inline-block; // Add this to allow center alignment
        border-bottom: 3px solid #f7b322;
      }
    }
  }

  // Milestones table
  .milestones-table {
    width: 80%;
    margin: 0 auto;

    // Individual milestone row
    .milestones-row {
      margin-bottom: 20px;
      padding: 10px;
      background-color: $milestone-bg-color;
      border-radius: 5px;
      overflow: hidden;

      // Year column
      .year {
        float: left;
        width: 100px;
        font-weight: bold;
        color: $milestone-text-color;
        font-size: 18px; /* Increased font size */
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-style: normal;
      }

      // Description column
      .description {
        margin-left: 120px;
        color: $milestone-text-color;
        font-size: 15px; /* Increased font size */
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
}
