// Internship.scss

.internship-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
  
    .internship-details {
      flex: 1;
      max-width: 600px;
  
      .internship-title {
        font-size: 24px;
        color: #333;
        margin-bottom: 10px;
      }
  
      .internship-description {
        font-size: 16px;
        color: #666;
        line-height: 1.6;
      }
  
      .internship-button-container {
        margin-top: 20px;
  
        .internship-button {
          display: inline-block;
          padding: 10px 20px;
          background-color: #007bff;
          color: #fff;
          text-decoration: none;
          border-radius: 5px;
          font-size: 16px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  
    .internship-image-container {
      flex: 1;
      max-width: 600px;
  
      .internship-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  
    @media screen and (min-width: 768px) {
        .internship-container {
          flex-direction: row;
        }
    
        .internship-details {
          max-width: 50%;
        }
    
        .internship-image-container {
          max-width: 50%;
        }
      }
    }