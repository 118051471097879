.banner_wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-height: 80vh; /* Set max-height to fit within the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Ensure the banner stays above other content */
    margin-top: 75px; /* Add margin to create space between navbar and image */
  
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto; /* Ensure the width adjusts automatically */
      height: auto; /* Ensure the height adjusts automatically */
      object-fit: contain; /* Maintain aspect ratio and fit within the container */
    }
  
    @media screen and (max-width: 768px) {
      max-height: 70vh; /* Adjust the max-height for smaller screens */
      img{
    margin-top: 55px; /* Add margin to create space between navbar and image */

      }
    }
  
    @media screen and (max-width: 480px) {
      max-height: 50vh; /* Adjust the max-height for even smaller screens */
    }
  }
  