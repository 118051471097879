// vision.scss
.vision-container {
  padding-top: 100px;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
  
}

.logo-card {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .logo {
    width: 200px;
    height: auto;
    border-radius: 10px;
    margin-right: 20px;
  }

  .logo-content {
    h2 {
      font-size: 24px;
      font-weight:700 ;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #555;
      line-height: 1.6;
    }
  }
}

.vision-mission-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  h2 {
    font-weight:700 ;

    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-size: 16px;
      color: #555;
      margin-bottom: 15px;
      line-height: 1.6;

      span {
        font-weight: bold;
        color: #f7b322; /* Yellow */
      }
    }
  }
}
