/* EducationNav.scss */

.NavBarEducation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #ff9b00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1),
                rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, 
                rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 4px 0;
    background-image: url('../../../assets/Education/Edu-nav.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  
    .navbaredu {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .logo-container {
        display: flex;
        align-items: center;
  
        .logo {
          img {
            max-height: 100px;
            max-width: 100px;
          }
        }
  
        .logo-txt {
          h1 {
            font-size: 2rem;
            margin-left: 10px;
            margin-top: 2px;
            color: white;
            font-weight: 700;
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          }
        }
      }
  
      .menu {
        list-style: none;
        color: white;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        gap: 20px;
        margin-right: 20px;
        margin-left: auto;
  
        a {
          text-decoration: none;
          color: white;
          font-weight: 500;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          padding: 8px 16px;
          white-space: nowrap; /* Prevent text from wrapping */
          transition: all 0.3s ease; /* Add smooth transition effect */
  
          &:last-child {
            margin-right: 0;
          }
  
          &.active {
            /* Remove background color and add underline for active link */
            background-color: transparent;
            border-bottom: 3px solid #ff9b00;
            padding-bottom: 5px;
          }
  
          @media screen and (max-width: 768px) {
            font-size: 1rem; /* Decrease font size for mobile view */
            padding: 5px 10px; /* Adjust padding for mobile view */
          }
        }
      }
    }
  
    @media screen and (min-width: 769px) {
      .menu a {
        font-size: 1.5rem;
      }
    }
  
    @media screen and (max-width: 768px) {
      .navbaredu {
        flex-direction: column;
        align-items: flex-start;
  
        .logo-container {
          .logo-txt {
            h1 {
              margin-top: 10px;
              font-size: 1.5rem;
            }
          }
        }
  
        .menu {
          margin-top: 20px;
          margin-left: auto;
        }
        .menu a.active {
          background-color: transparent;
          border-bottom: 3px solid #ff9b00;
          padding-bottom: 5px;
        }
      }
    }
  }
  