/* numberCount.scss */
.numcount-1 {
  position: relative;
  margin-top: 5%;
  gap: 10px;
  h3 {
    margin-left: 10%;
    display: flex;
    justify-content: flex-start;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    text-decoration: wavy;
    padding: 0;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  h3 span {
    position: relative;
    width: 400%;
    border-bottom: 3px solid #f7b322;
  }
  h3 span:before {
    content: "";
    position: absolute;
    margin-top: 10px;
    width: 30%;
    height: 20px;
    bottom: -3px;
    left: 0;
    background-color: #f7b322;
    @media (max-width: 768px) {
      width: 0; /* Set width to zero for smaller screens */

    }
  }
  // .count-background {
  //   background-color: #482d4c; /* Background color for counts */
  //   border-radius: 5px; /* Rounded corners for the background */
  //   padding: 10px; /* Padding around the counts */
  //   margin-top: 20px; /* Margin to separate from the title */
  // }
  .cont1 {
    margin-top: 5%;
    // width: 100%;
    padding: 10px; /* Reduced padding for better mobile responsiveness */
    display: flex;
    // gap: 10px;
    flex-wrap: nowrap; /* Prevent wrapping to the next row */
    background-color: #482d4c; /* Background color for counts */
    border-radius: 5px; /* Rounded corners for the background */
    padding: 20px; /* Padding around the counts */
    margin-top: 20px; /* Margin to separate from the title */
  }

  .cont {
    margin: 5px; /* Adjust margin as needed for spacing */
    width: calc(25% - 10px); /* Adjust the width of each count element for a 4-column layout */
    border-radius: 5px;
    gap: 10%;
  }

  .value1 {
    font-size: 61px;
    font-weight: bold;
    color: #ffffff; /* Text color */
    font-family: "Arial", sans-serif; /* Alternative font */
    animation: fadeIn 1s ease-in;
    // background-color: #482d4c; /* Highlight background color */
    padding: 10px; /* Adjust padding to add spacing around the count */
    border-radius: 5px; /* Add border radius for rounded corners */
  }

  .label1 {
    margin-top: 7%;

    color: #ffffff; /* Label color */
    font-size: 25px;
    font-weight: bold;
    font-family: "Arial", sans-serif; /* Alternative font */
    animation: fadeIn 1s ease-in;
  }

  .horizontal-line1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    width: 100%;
    height: 3px;
    justify-content: center;
    background-color: #ffffff; /* Line color */
  }

  .horizontal-line2 {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 10px;
    background-color: #f7b322; /* Line color */
  }

  @media screen and (max-width: 768px) {
    .cont1 {
      flex-direction: column; /* Display counts in a single column on smaller screens */
      align-items: center;
    }

    .cont {
      width: 100%; /* Make each count occupy the full width */
      text-align: center;
      margin: 10px 0; /* Adjust margin for spacing */
    }
  }
}
