/* PharmacyCollege.scss */

/* Define a class for the spacer */
// .spacer {
//   height: 100px; /* Adjust the height as needed */
//   display: block;
//   visibility: hidden; /* Hide the spacer visually */
// }

/* Add top margin to the sections to create space for the fixed navbar */
.section {
    padding-top: 40px; /* Match the height of the spacer */
  }
    
  
  .floating-whatsapp-icon {
    position: fixed;
    bottom: 60px;
    right: 40px;
    width: 50px;
    height: 45px;
    border-radius: 50%;
    background-color: #25d366;
    color: #fff !important;
    text-align: center;
    font-size: 30px;
    z-index: 999;
    display: flex;
    justify-content: center; /* Horizontally center the icon */
    align-items: center; /* Vertically center the icon */
  }
  
  // @media (max-width: 768px) {
  //   .floating-whatsapp-icon {
  //     top: 80%;
  //     left: 90%;
  //     transform: translate(-50%, -50%);
  //   }
  // } 	