/* PharmacyCollege.scss */

/* Define a class for the spacer */
// .spacer {
//   height: 100px; /* Adjust the height as needed */
//   display: block;
//   visibility: hidden; /* Hide the spacer visually */
// }

/* Add top margin to the sections to create space for the fixed navbar */
.section {
  padding-top: 0px !important; /* Match the height of the spacer */
}
  
#pharmbanner{
  margin-top: 8%;
  margin-bottom: 2%;
}

.floating-whatsapp-icon {
  position: fixed;
  bottom: 60px;
  right: 40px;
  width: 50px;
  height: 45px;
  border-radius: 50%;
  background-color: #25d366;
  color: #fff !important;
  text-align: center;
  font-size: 30px;
  z-index: 999;
  display: flex;
  justify-content: center; /* Horizontally center the icon */
  align-items: center; /* Vertically center the icon */
}

@media (max-width: 768px) {
  #pharmbanner{
    margin-top: 40%;
  }
  
} 	

.floating-enquiry-icon {
  position: fixed;
  bottom: 120px; /* Adjust as needed to place it above the WhatsApp icon */
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff; /* Adjust the color as needed */
  color: #fff;
  text-align: center;
  font-size: 30px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.floating-enquiry-icon:hover {
  background-color: #0056b3; /* Darken the color on hover */
}

.enquiry-link {
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Inherit the color from parent */
}
