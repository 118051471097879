.founder-history-container {
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-bottom: 40px;
  text-align: center; /* Center align the content */

  .chairman-message {
  padding-top: 100px;

    display: inline-block; /* Display as inline block to center */
    text-align: left; /* Reset text alignment for inner content */

    .image {
      
      margin: 0 auto; /* Center the image horizontally */
      width: 300px; /* Set width to make it a square shape */
      height: 400px; /* Set height to match width for a square */
      border-radius: 10px; /* Rounded corners */
      overflow: hidden; /* Hide overflow */
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .details {
      margin-top: 20px; /* Add spacing between image and details */
      .name-title {
        h2 {
          font-size: 20px;
          color: #333;
          // margin-bottom: 5px;
      text-align: center; /* Center align the name and title */ 


        }

        h3 {
          font-size: 24px;
          color: #333;
      text-align: center; /* Center align the name and title */ 

          // margin-bottom: 20px;

        }
      }

      .content {
        flex-grow: 1;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #fff;

        h2 {
          font-size: 24px;
          color: #333;
          margin-bottom: 15px;
          border-bottom: 2px solid #f7b322;
          padding-bottom: 10px;
      text-align: center; /* Center align the name and title */ 
           
        }

        .description {
          p {
            font-size: 16px;
            color: #666;
            line-height: 1.6;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}