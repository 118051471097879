/* carousel.scss */

.slider-wrapper {
  width: 100%;
  padding-top: 110px;
// margin-top: %;
  .slider-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 1400px; /* Adjust max-width as needed */
    height: 85.5vh; /* Adjust height as needed */
    // max-height: 650px; /* Maximum height */
    // border-radius: 10px;
    overflow: hidden;
    .transitioning .slides {
      transition: opacity 0.9s ease-in-out;
    }
    
    .myslide {
      height: 80%;
      width: 80%;
      background-size: cover;
      background-position: center;
      transition: background-image 0.6s ease-in-out;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 45px;
      color: #fff;
      z-index: 1;
      cursor: pointer;
    }

    .left-arrow {
      left: 32px;
    }

    .right-arrow {
      right: 32px;
    }

    .dots-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      z-index: 1;

      .dot {
        margin: 0 3px;
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .dot.active {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .slider-container {
    height: 80vw; /* Adjust height for smaller screens */
    max-height: 500px; /* Maximum height for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .slider-container {
    height: 90vw; /* Adjust height for even smaller screens */
    max-height: 400px; /* Maximum height for even smaller screens */
  }
}
