/* ArtsNav.scss */

.NavBarArts {
  position: fixed;
  top: 4.5%  ;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #c54923;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1),
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-image: url("../../../assets/arts-navbar.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  @media (max-width: 600px) { /* Adjust this value based on your mobile breakpoint */
  top: 0;
   
  }
  .navbarArts {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-container {
      display: flex;
      align-items: center;

      .logo img {
        padding: 5px;
        max-height: 80px;
        max-width: 80px;
      }

      .logo-txt h1 {
        font-size: 3.3rem;
        margin-top: 25px;
        color: white;
        font-weight: 800;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        @media only screen and (max-width: 600px) {
          .logo-txt h1 {
            padding-top: 7%; /* Adjusted padding top for smaller screens */
          }}
        
      }
    }

    .menu {
      list-style: none;
      color: white;
      padding: 0;
      font-size: 1.5rem;
      font-weight: 500;
      display: flex;
      gap: 25px;
      margin-right: 20px;
      margin-top: 20px;

      margin-left: auto;

      a {
        text-decoration: none;
        color: white;
        font-weight: 500;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        padding: 8px 12px;
        white-space: nowrap;
        transition: all 0.3s ease;

        &.active {
          background-color: transparent;
          border-bottom: 3px solid #dcd1cd;
          padding-bottom: 5px;
        }
      }

      ul {
        display: flex;
      }

      .dropdown {
        position: relative;

        &:hover .nav-link.dropdown-toggle {
          background-color: transparent;

          .dropdown-menu {
            display: block;
          }
        }

        .nav-link.dropdown-toggle {
          background-color: transparent;
          color: white;
          border: none;
          text-decoration: none;
          cursor: pointer;
          font-weight: 500;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          white-space: nowrap;

          &:hover {
            background-color: transparent;
          }

          &.active {
            background-color: transparent;
            border-bottom: 3px solid #dcd1cd;
            padding-bottom: 5px;
          }

          .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: auto;
            display: none;
            background-color: white;
            border: none;
            border-radius: 0;
            box-shadow: none;
            min-width: 160px;
            z-index: 1;
            color: black;

            a {
              color: black;
              top: 0;
              padding: 10px 15px;
              text-decoration: none;
              display: block;
              transition: background-color 0.3s;

              .dropdown-menu-2 {
                position: absolute;
                top: 0;
                left: -70%;
                display: none;
                background-color: white;
                border: none;
                border-radius: 0;
                box-shadow: none;
                min-width: 160px;
                z-index: 1;
                color: black;
                list-style: none;
              }
            }
          }
        }
      }
    }
  }
  
  .mobile-menu-icon {
    display: none;
    cursor: pointer;

    .burger {
      width: 30px;
      height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .line1,
      .line2,
      .line3 {
        width: 100%;
        height: 4px;
        background-color: white;
        transition: all 0.3s ease;
      }

      &.open {
        .line1 {
          transform: translateY(9px) rotate(45deg);
        }

        .line2 {
          opacity: 0;
        }

        .line3 {
          transform: translateY(-9px) rotate(-45deg);
        }
      }
    }
  }

  .mini-navbar {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #c54923;
    padding: 10px 0;
    text-align: center;

    a {
      display: block;
      color: white;
      padding: 10px;
      font-size: 1.2rem;
      text-decoration: none;
      transition: all 0.3s ease;

      &.active {
        border-bottom: 2px solid white;
      }
    }

    &.open {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    .navbarArts {
      .logo-txt h1 {
        font-size: 1.7rem !important;
      }
      .menu {
        display: none;
      }
  
      .mobile-menu-icon {
        display: block;
      }
    }
  }
}
