.programs-container-arts-ug {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .program-header {
    text-align: center;
    padding: 20px 0;

    h1 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    img {
      width: 80%;
      max-width: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .program-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-content: center; // Center grid boxes horizontally

    .card {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }

      h2 {
        font-weight: bold;
        font-size: 1.8rem;
        color: #c54923;
        margin-bottom: 10px;
      }

      p, ul {
        font-size: 1.4rem;
        line-height: 1.6;
        color: #333;
      }

      ul {
        list-style-type: disc;
        padding-left: 20px;

        li {
          margin-top: 10px;
        }
      }
    }
  }
}
