.placements-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  padding-top: 120px;

  .placement-section {
    display: flex;
    align-items: center;
    max-width: 800px;
    width: 100%;

    .placement-image {
      width: 400px;
      border-radius: 10px;
      margin-right: 20px;
    }

    .placement-details {
      flex: 1;

      .placement-title {
        font-size: 24px;
        color: #333;
        margin-bottom: 10px;
      }

      .placement-description {
        font-size: 16px;
        color: #666;
        line-height: 1.6;
      }
    }
  }

  .companies-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .company {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 300px;

      .company-image {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .company-details {
        padding: 20px;

        .company-title {
          font-size: 20px;
          color: #333;
          margin-bottom: 10px;
        }

        .company-description {
          font-size: 16px;
          color: #666;
          line-height: 1.6;
        }
      }
    }
  }
}
