.programs-wrap {
  position: relative;

  .programs-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px; // Adjusted gap for smaller screens
    flex-direction: row; // Arrange programs in a row

    .programsCard {
      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      padding: 15px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; // Center align text

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        object-fit: cover;
        aspect-ratio: 1.5;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 17px;
        font-family: "Open Sans", sans-serif;
        color: #333;
        margin-top: auto;
        margin-bottom: 0; // Adjusted margin to center text below the image
      }

      &:hover {
        color: green;
        transition: 0.5s;
        transform: scale(1.05);
      }
    }

    @media screen and (min-width: 768px) {
      justify-content: space-between;

      .programsCard {
        width: calc(50% - 16px); // Adjusted width for smaller gap
        flex-basis: calc(50% - 16px); // Adjusted basis for smaller gap
      }
    }

    @media screen and (min-width: 1200px) {
      justify-content: center;

      .programsCard {
        width: calc(33.33% - 16px); // Adjusted width for smaller gap
        flex-basis: calc(33.33% - 16px); // Adjusted basis for smaller gap
      }
    }

    @media screen and (min-width: 1600px) {
      .programsCard {
        width: calc(25% - 16px); // Adjusted width for smaller gap
        flex-basis: calc(25% - 16px); // Adjusted basis for smaller gap
      }
    }
  }

  .programsPopup {
    position: fixed;
    top: 50%; // Center vertically
    left: 50%; // Center horizontally
    transform: translate(-50%, -50%); // Center the popup
    max-width: 90vw; // Adjusted maximum width for smaller screens
    max-height: 90vh; // Adjusted maximum height for smaller screens
    overflow-y: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    z-index: 9999;

    .CloseButton {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 24px;
      color: #333;
    }
  }
}
