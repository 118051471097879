// admission.scss

.admission-container {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px;
  padding-top: 100px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* Three columns on large screens */
    grid-template-rows: auto auto; /* Two rows */
    grid-template-areas: 
      "card1 card2 card3"
      "video video video"; /* Video spans all three columns */
  }

  .admission-card:nth-child(1) { grid-area: card1; }
  .admission-card:nth-child(2) { grid-area: card2; }
  .admission-card:nth-child(3) { grid-area: card3; }
  .admission-vid { grid-area: video; }
}

.admission-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: box-shadow 0.3s ease;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 50px 0;

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 900;
  }

  p {
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  a {
    color: inherit;
    text-decoration: none;
    background-color: #388e3c;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 13px;
    transition: background-color 0.3s ease;
    
    &:hover {
      background-color: #2c6e2c;
    }
  }

  .admission-link {
    margin-top: 10px; /* Add spacing between buttons if both are present */
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.admission-vid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  
  .video-wrapper {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: 768px) {
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
    }
  }

  .vid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
      height: auto;
    }
  }
}

@media screen and (max-width: 1199px) {
  .admission-container {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
    grid-template-areas: 
      "card1"
      "card2"
      "card3"
      "video"; /* Video spans all columns */
  }
}

