.CampusTourwrapper {
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  width: 90%; /* Adjust width for responsiveness */
  background: none;
  margin-bottom: 5%;
  .campusTour-title {
    h3 {
      margin-left: 10%;
      display: flex;
      justify-content: flex-start;
      font-size: 30px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      text-decoration: wavy;
      padding: 0;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

      span {
        position: relative;
        width: 400%;
        border-bottom: 3px solid #f7b322;
      }

      span:before {
        content: "";
        position: absolute;
        margin-top: 10px;
        width: 36%;
        height: 20px;
        bottom: -3px;
        left: 0;
        background-color: #f7b322;

        @media (max-width: 768px) {
          width: 0;
        }
      }
    }
    
  }

  .video {
    .video-wrapper {
      margin-top: 20px;
      width: 100%;
      overflow: hidden;
      position: relative;
      padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    .vid {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
 input[type="radio"] {
      display: none;
    }
    h3 {
      margin-top: 10%;
      margin-left: 10%;
      display: flex;
      justify-content: flex-start;
      font-size: 30px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      text-decoration: wavy;
      padding: 0;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

      span {
        position: relative;
        width: 400%;
        border-bottom: 3px solid #f7b322;
      }

      span:before {
        content: "";
        position: absolute;
        margin-top: 10px;
        width: 26%;
        height: 18px;
        bottom: -3px;
        left: 0;
        background-color: #f7b322;

        @media (max-width: 768px) {
          width: 0;
        }
      }
    }
  .container__slider-CampusTourwrapper {
    height: 350px;
    width: 100%;
    display: flex;

    .container {
      margin: 0;
      max-width: 60%;
      height: 100%;
      max-height: 600px;
      // transform-style: preserve-3d;
      align-items: center;

      .cards {
        margin-top: 10%;
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        .card {
          background: none;
          border: none;
          position: absolute;
          width: 60%;
          height: 100%;
          left: 0;
          right: 0;
          margin: auto;
          cursor: pointer;
          transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      background: none;
     
      
    }

    .hover-text {
      justify-content: center;
      color: white;
      margin-top: 2%;
      margin-left: 38%;
      font-size: larger;
    }
  }

  /* Slide transition styles */
  #item-2:checked ~ .cards #selector-1,
  #item-1:checked ~ .cards #selector-6,
  #item-3:checked ~ .cards #selector-2,
  #item-4:checked ~ .cards #selector-3,
  #item-5:checked ~ .cards #selector-4,
  #item-6:checked ~ .cards #selector-5 {
    transform: translateX(-40%) scale(0.8);
    // opacity: 0.6;
    z-index: 0;
  }

  #item-3:checked ~ .cards #selector-1,
  #item-2:checked ~ .cards #selector-6,
  #item-4:checked ~ .cards #selector-2,
  #item-5:checked ~ .cards #selector-3,
  #item-6:checked ~ .cards #selector-4,
  #item-1:checked ~ .cards #selector-5 {
    transform: translateX(40%) scale(0.8);
    // opacity: 0.6;
    z-index: 0;
  }

  #item-4:checked ~ .cards #selector-1,
  #item-3:checked ~ .cards #selector-6,
  #item-5:checked ~ .cards #selector-2,
  #item-6:checked ~ .cards #selector-3,
  #item-1:checked ~ .cards #selector-4,
  #item-2:checked ~ .cards #selector-5 {
    transform: translateX(80%) scale(0.6);
    // opacity: 0.6;
    z-index: 0;
  }

  #item-5:checked ~ .cards #selector-1,
  #item-4:checked ~ .cards #selector-6,
  #item-6:checked ~ .cards #selector-2,
  #item-1:checked ~ .cards #selector-3,
  #item-2:checked ~ .cards #selector-4,
  #item-3:checked ~ .cards #selector-5 {
    transform: translateX(120%) scale(0.5);
    // opacity: 0.6;
    z-index: 0;
  }

  #item-6:checked ~ .cards #selector-1,
  #item-5:checked ~ .cards #selector-6,
  #item-1:checked ~ .cards #selector-2,
  #item-2:checked ~ .cards #selector-3,
  #item-3:checked ~ .cards #selector-4,
  #item-4:checked ~ .cards #selector-5 {
    transform: translateX(140%) scale(0.4);
    // opacity: 0.6;
    z-index: 0;
  }

  #item-1:checked ~ .cards #selector-1,
  #item-6:checked ~ .cards #selector-6,
  #item-2:checked ~ .cards #selector-2,
  #item-3:checked ~ .cards #selector-3,
  #item-4:checked ~ .cards #selector-4,
  #item-5:checked ~ .cards #selector-5 {
    transform: translateX(0) scale(1.2);
    z-index: 1;
  }

  @media (max-width: 768px) {
    /* Styles for smaller screens */
    .campusTour-title {
      h3 {
        margin-left: auto;
        margin-right: auto;
        font-size: 24px; /* Decrease font size for smaller screens */
      }
  
     
    }
  
    @media (max-width: 768px) {
      .container__slider-CampusTourwrapper {
        width: 90%;
        margin-left: auto;
        margin-right: auto; 
      }
    h3{
margin-top: 20%;
        font-size: 24px; /* Decrease font size for smaller screens */
justify-content: center;
margin-left: 0;
    }
      .container {
        margin-top: 3%;
        max-width: 80%;
      }
    
      .cards .card {

        
        // width: 90%;
        img {
        margin-top: 50%;
background: none;
          height: 50%;
          width: 300%;
        }
      }
    
      .hover-text {
        margin-left: 0;
        margin-right: auto;
        // font-size: 10px;
        // display: none;
        justify-content: center;
        display:flex
      }
    }
    
  
  }}
