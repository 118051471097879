.Lab4 {
 
  height: 100%;

  .fixed-size-image {
    width: 200px; /* Set a fixed width for other PNG images */
    height: 200px; /* Set a fixed height for other PNG images */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
   margin-left: 180px;
    margin-top: 10px;
    border-radius: 10px;
  }

  background-color: #efffd5;
  height: 100%;

  .Lab4-Container {
    padding: 100px;

    .department4 {
      h2 {
        background-color: #188a10;
        padding: 20px;
        color: white;
        border-radius: 8px;
        font-weight: bolder;
        font-size: 30px;
        text-align: center;
        font-family: "Varela Round", sans-serif;
      }
      img.top-banner {
        max-width: 100%;
        margin: 0 auto; /* Center the image horizontally */
        display: block; /* Remove extra space below inline images */
        border-radius: 10px;
      }
      p {
        font-family: "din", sans-serif;
        font-size: 18px;
        line-height: 1.5;
        text-align: center;
        font-family: "Montserrat", sans-serif;
      }
    }

    .Resource,
    .Research,
    .Mentor,
    .Intern {
      display: flex;
      flex-direction: row;
      align-items: center; /* Vertically align items */
      gap: 20px; /* Reduced gap */
      background-color: #60c98c2b;
      padding: 20px;
      border-radius: 16px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

      .Resourceimage,
      .Researchimage,
      .mentorimage,
      .internimage {
        img {
          // max-width: 100%;
          margin-left: 0;
          height: auto;
        }
      }

      .ResourceInfo,
      .ResearchInfo,
      .Mentorinfo,
      .interninfo {
        h2 {
          border-radius: 8px;
          font-family: loraregular;
          font-weight: bolder;
          font-size: 24px;
          text-align: center;
          font-family: "Varela Round", sans-serif;
          margin-top: 0; /* Add this line to align h2 tags vertically */
        }
        p {
          font-size: 16px;
          margin-left: 0;
          line-height: 1.5;
          font-family: "Montserrat", sans-serif;
        }
      }
    }
  }

  @media (max-width: 768px) {
  

    .fixed-size-image {
      /* Adjust dimensions for fixed-size images in mobile view */
      width: 100px;
      height: 100px;
    }
    
    .Lab4-Container {
      padding: 20px;

      .department4 {
        h2 {
          font-size: 24px;
        }
        img.top-banner{
          height: 300px;
        }
        p {
          font-size: 14px;
          line-height: 1.4;
        }
      }

      .Resource,
      .Research,
      .Mentor,
      .Intern {
        flex-direction: column;
        gap: 10px; /* Reduced gap */
        padding: 10px; /* Reduced padding */

        .Resourceimage,
        .Researchimage,
        .mentorimage,
        .internimage {
          img {
            max-width: 100%;
            margin-left: 0;
            height: auto;
          }
        }
        .ResourceInfo,
        .ResearchInfo,
        .Mentorinfo,
        .interninfo {
          h2 {
            font-size: 18px;
            margin-top: 20px;
          }
          p {
            font-size: 12px;
            margin-left: 0;
            line-height: 1.4;
          }
        }
      }
    }
  }
}
