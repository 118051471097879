.courses-wrap-nursing {
  text-align: center;
  padding: 40px 0; /* Add spacing at the top and bottom */

  .courses-container1 {
    max-width: 80%;
    margin: 0 auto;
    text-align: left;
    padding: 0 20px; /* Adjust padding */
    box-sizing: border-box; /* Ensure padding is included in the width */

    h2 {
      font-size: 2.5rem;
      font-weight: bold;
      background-color: #3c003e;
      color: white;
      display: inline-block;
      padding: 10px;
      width: 100%;
      text-align: center;
    }

    p {
      font-size: 2rem;
      line-height: 1.5;
      color: #333;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 20px;
      margin: 20px 0; /* Add spacing */
    }

    .programs-wrap-nursing {
      .programs-nursing {
        justify-items: center;

        .programs-list-nursing {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
          gap: 20px; /* Add spacing between cards */
          justify-items: center;

          .program-card {
            background-color: #ffffff;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
              rgba(0, 0, 0, 0.23) 0px 3px 6px;
            padding: 15px;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;

            img {
              max-width: 100%; /* Ensure images don't exceed their container */
              height: auto; /* Maintain aspect ratio */
              border-radius: 10px; /* Apply border radius */
            }

            h3 {
              font-size: 17px;
              font-family: "Open Sans", sans-serif;
              margin-top: 10px; /* Add spacing */
            }

            &:hover {
              color: green;
              transition: 0.5s;
              transform: scale(1.05);
            }
          }
        }

        .program-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1001;

          .program-popup-content {
            background-color: #fff;
            padding: 10px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            position: relative;
            width: 90%;
            max-height: 90%;
            overflow: auto;
            z-index: 1002;
          }

          .close-button {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 40px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
