/* Navbar.scss */
.home-nav-inst12 {
  position: fixed;
  top: 0%;
  z-index: 999;
  width: 100%; // Ensure full width

  .myNavbar {
    height: 80px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 0;
    background-color: rgb(252, 254, 255);
    // color: rgb(69, 46, 73);
    // z-index: 9;

    /* Ensure navbar stays above other content */
    .logo-container {
      // display: flex;
      // align-items: center; // Align logo vertically

      .logo {
        height: 80px;
        // margin-right: 20px; // Add margin between logo and nav links
      }
    }

    .nav-links {
      background-color: rgb(252, 254, 255);
      height: 100%;
      // flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      // justify-content: flex-end;

      ul {
        height: 100%;
        list-style: none;
        display: flex;
        justify-content: space-between;
        // align-items: center;

        padding: 0;
        margin: 0;
        // gap: 17px;

        li {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px; // Adjust spacing between nav items
          cursor: pointer;

          a {
            text-decoration: none;
            color: rgb(75, 47, 81);
            font-size: 16px;
            font-weight: 600;
          }

          .dropdown-content {
            display: flex;
            // flex-direction: column;
            justify-content: space-around;
            gap: 20px;
            overflow: hidden;
            // height: 100%;
            padding: 10px 5px;
            // padding-top: 50px;
            // display: none;
            // height: 0;
            position: absolute;
            top: 100%;
            // margin-top: 26%;
            width: 200px; /* Increase the width */
            left: 4%;

            background-color: white;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            z-index: 1;
            height: 150px;
            animation: dropdown_content_ani 0.3s linear;

            @keyframes dropdown_content_ani {
              0% {
                height: 0%;
              }
              100% {
                // height: 110px;
              }
            }

            /* Add top line */
            &::before {
              content: "";
              position: absolute;
              top: 0; /* Adjust position as needed */
              left: 50%; /* Position it at the center */
              transform: translateX(-50%);
              width: 200px; /* Set the width of the top line */
              height: 7px; /* Set the height of the top line */
              border-radius: 2px;

              background-color: rgb(
                228,
                163,
                38
              ); /* Set the color of the top line */
            }

            a {
              display: block;
              transition: border 0.2s linear;
              // padding: 6px; /* Adjust the padding to reduce the height */
              // color: #333;
              // text-decoration: none;
              // font-size: 15px;
              font-weight: 600;
              // font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
              //   "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

              &:hover {
                background-color: #ddd;
                // border-bottom: 2px solid rgb(228,163,38);
              }
            }
          }
        }
      }
      .admission-button {
        a {
          color: #fff;
        }
      }

      .min_navbar {
        display: block;
        .sidebar {
          display: flex;
          position: absolute;
          justify-content: start;
          padding: 50px 0;
          padding-left: 20px;
          top: 0;
          left: 0;
          width: 50%;
          height: 100vh;
          background-color: rgba(255, 255, 255, 0.9);
          z-index: 100;
          // padding-top: 100px; // Adjust for logo height
          transition: transform 0.3s ease-in-out;

          ul {
            list-style: none;
            display: flex;
            justify-content: start;
            flex-direction: column;
            text-align: left;
            gap: 20px;
            // align-items: flex-start;
            height: 20%;
            padding: 0;
            margin: 0;

            li {
              margin-right: 0;
              margin-bottom: 10px;

              a {
                text-decoration: none;
                color: rgb(75, 47, 81);
                font-size: 16px;
                font-weight: 600;
              }
            }

            .dropdown {
              // position: relative;

              a {
                cursor: pointer;
                text-decoration: none;
                color: rgb(75, 47, 81);
                font-weight: 600;
                font-size: 16px;
              }

              .show {
                margin: 7px;
              }

              .dropdown-content {
                padding: 10px 5px;
                display: none;
                position: absolute;
                top: 100%;
                // margin-top: 45%;
                width: 200px; /* Increase the width */
                height: 300px;
                left: 10%;
                background-color: #f9f9f9;
                border-radius: 4px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                z-index: 1000;
                /* Add top line */
                &::before {
                  content: "";
                  position: absolute;
                  top: 1px; /* Adjust position as needed */
                  left: 23%; /* Position it at the center */
                  transform: translateX(-50%);
                  width: 90px; /* Set the width of the top line */
                  height: 5px; /* Set the height of the top line */
                  border-radius: 4px;

                  background-color: rgb(
                    228,
                    163,
                    38
                  ); /* Set the color of the top line */
                }

                a {
                  display: block;
                  padding: 6px; /* Adjust the padding to reduce the height */
                  color: #333;
                  text-decoration: none;
                  font-size: 13px;
                  font-weight: 500;
                  font-family: "Lucida Sans", "Lucida Sans Regular",
                    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
                    sans-serif;

                  &:hover {
                    background-color: #ddd;
                  }
                }
              }
              
              &:hover {
                background-color: #ddd;
              }
            }
          }
        }
      }
    }

    .admission-button a {
      background-color: rgb(113, 60, 124);
      color: #fff;
      padding: 10px 20px;
      text-decoration: none;
      font-size: 18px;
      font-weight: 600;
      border-radius: 5px;
    }
    .burger-icon {
      /* Initially hide the burger icon for larger screens */
      display: none;
    }
    @media screen and (max-width: 450px) {
      .logo-container {
        .logo {
          height: 70px;
          width: 250px;
          margin-right: 20px; // Add margin between logo and nav links
        }
      }
      .min_navbar {
        .sidebar {
          width: 70vw;
        }
      }
    }

    @media screen and (max-width: 300px) {
      .logo-container {
        .logo {
          width: 180px;
          margin-right: 20px; // Add margin between logo and nav links
        }
      }
    }

    @media screen and (max-width: 1070px) {
      .nav-links ul {
        display: none; /* Hide the regular navbar */
      }

      .min_navbar {
        display: block; /* Ensure the min_navbar is visible */
      }

      .burger-icon {
        display: block; /* Ensure the min_navbar is visible */

        color: #6e1d6e;
        font-size: 25px;
        padding-right: 30px;
        font-weight: 700;
        cursor: pointer; /* Add cursor pointer for better UX */
      }

      .sidebar {
        width: 70vw; /* Adjust sidebar width for better visibility */
      }
    }
  }

  .contact-info-section {
    background-color: #6e1d6e;
    padding: 0px 20px;
    display: flex;
    // justify-content: center;
    align-items: center;
    // padding-bottom: 20px;

    .contact-info-container {
      display: flex;
      flex-wrap: wrap; // Allow contact info to wrap on smaller screens
      justify-content: center;

      .phone-icon,
      .email-icon {
        font-size: 20px;
        margin-right: 10px;
        color: #ffffff;
      }

      .phone-number,
      .email {
        color: #ffffff;
        font-size: 16px;
        margin-right: 20px;
        margin-top: 2px;
      }
    }
  }
  .image-container {
    position: absolute;
    top: 0px; /* Height of the navbar */
    right: -1px;
    z-index: 10; /* Set a lower z-index than the navbar */
    // height: 10x;
    .centered-image {
      position: relative;
      // top: 0;
      // right: 0;
      // transform: translateY(-50%); /* Center vertically */
      max-width: 100%; /* Ensure image doesn't exceed container width */
    }
    .menu-items {
      position: absolute;
      top: 8px; /* Adjust as needed */
      right: 10px; /* Adjust as needed */
      display: flex;
      z-index: 11;

      // align-items: center;
    }

    .menu-item {
      color: white;
      font-size: 14px;
      font-weight: bold;
      margin-left: 10px;
      margin-right: 10px; /* Adjust as needed */
    }

    .separator {
      color: white;
      font-size: 16px;
      margin-right: 10px; /* Adjust as needed */
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 870px) {
    .image-container {
      display: none;
    }
    .contact-info-section {
      display: none;
    }
  }
  .sidebar-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: #220c0c;
    cursor: pointer;
    font-size: 20px;
    font-weight: 900;
    transition: transform 0.3s ease; /* Add transition for smooth animation */
  }

  .mobile-navbar {
    display: none; /* Hide by default */

    @media (max-width: 767px) {
      display: block; /* Display on small screens */
      background-color: rgb(228, 163, 38); /* Full-width background color */
      color: white; /* Text color */
      padding: 10px 0; /* Padding for content */
    }

    .menu-item {
      margin: 0 10px;
      cursor: pointer;
      text-decoration: none;
    }

    .separator {
      margin: 0 5px;
    }
  }
}
