.PharmFoot {
  background: linear-gradient(to bottom, #016102, #024a00);
  padding: 30px 0;
  text-align: center;

  .list-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .list {
      margin-bottom: 40px;
      color: white;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana;
      flex: 1 1 300px;

      h3 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 30px;
        text-transform: uppercase;
      }

      h4 {
        font-size: 1.8rem;
        margin-bottom: 20px;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: #3eb714;
        }
      }

      .list.get-in-touch {
        h3 {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }

        .contact-info {
          display: flex;
          flex-direction: column;

          .address,
          .phone,
          .email {
            display: flex;
            align-items: center;
            color: white;
            // font-size: 1rem;
            margin-bottom: 10px;

            svg {
              font-size: 1.2rem;
              margin-right: 5px;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .social-link {
    margin-top: 50px;
    color: white;

    h4 {
      font-size: 1.5rem;
      margin-bottom: 30px;
      text-transform: uppercase;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      gap: 30px;

      svg {
        font-size: 3.5rem;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }

  .additional-info {
    margin-top: 50px;
    color: white;
    font-size: 1.8rem;

    p {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .list-container {
      flex-direction: column;

      .list {
        flex: 1 1 100%;
        margin-bottom: 20px;

        h3 {
          font-size: 2rem;
          margin-bottom: 20px;
        }

        h4 {
          font-size: 1.5rem;
          margin-bottom: 15px;
        }
      }
    }

    .social-link {
      margin-top: 30px;
      margin-bottom: 50px;

      h4 {
        margin-bottom: 20px;
      }

      .icon-container {
        svg {
          font-size: 3rem;
        }
      }
    }

    .additional-info {
      font-size: 1.5rem;
    }
  }
}
