.Lab1 {
  height: 100%;
  background-color: #efffd5;

  .Lab1-Container {
    padding: 100px;

    .department1 {
      h2 {
        background-color: #188a10;
        padding: 20px;
        color: white;
        border-radius: 8px;
        font-weight: bolder;
        font-size: 30px;
        text-align: center;
        font-family: "Varela Round", sans-serif;
      }
      img.top-banner {
        max-width: 100%;
        margin: 0 auto; /* Center the image horizontally */
        display: block; /* Remove extra space below inline images */
        border-radius: 10px;
      }
      p {
        font-family: "din", sans-serif;
        font-size: 18px;
        line-height: 1.5;
        text-align: center;
        font-family: "Montserrat", sans-serif;
      }
    }

    .Resource,
    .Research,
    .Mentor,
    .Intern {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      background-color: #60c98c2b;
      padding: 20px;
      border-radius: 16px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

      .Resourceimage,
      .Researchimage,
      .mentorimage,
      .internimage {
        img {
          max-width: 100%;
          margin: 0 auto; /* Center the image horizontally */
          display: block; /* Remove extra space below inline images */
          height: auto;
        }
      }

      .ResourceInfo,
      .ResearchInfo,
      .Mentorinfo,
      .interninfo {
        h2 {
          border-radius: 8px;
          font-family: loraregular;
          font-weight: bolder;
          font-size: 24px;
          text-align: center;
          font-family: "Varela Round", sans-serif;
          margin-top: 0;
        }
        p {
          font-size: 16px;
          line-height: 1.5;
          text-align: center;
          font-family: "Montserrat", sans-serif;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .Lab1-Container {
      padding: 20px;

      .department1 {
        h2 {
          font-size: 24px;
          height: auto;
        }
        img.top-banner{
          height: auto;
          max-height: 150px;
          max-height: auto; /* Remove the max-height property */

        }
        p {
          font-size: 14px;
          line-height: 1.4;
        }
      }

      .Resource,
      .Research,
      .Mentor,
      .Intern {
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        .Resourceimage,
        .Researchimage,
        .mentorimage,
        .internimage {
          img {
            max-width: 100%;
            margin: 0 auto;
            display: block;
            height: auto;
          }
        }
        .ResourceInfo,
        .ResearchInfo,
        .Mentorinfo,
        .interninfo {
          h2 {
            font-size: 18px;
            margin-top: 20px;
          }
          p {
            font-size: 12px;
            line-height: 1.4;
          }
        }
      }
    }
  }
}
