.payment-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-top: 120px;

  
    h1 {
      font-size: 2rem;
      margin-bottom: 20px;
      color: #333;
    }
  
    .qr-codes-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      margin-bottom: 40px;
  
      .qr-code-column {
        text-align: center;
        border-radius: 8px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        width: calc(33.33% - 20px); /* Adjust column width for responsiveness */
  
        img {
          max-width: 100%;
          height: auto;
          margin-bottom: 10px;
          transition: transform 0.3s ease-in-out;
        }
  
        p {
          font-size: 1rem;
          color: #666;
        }
  
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  
   .how-to-pay-container {
  margin-top: 20px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }

  .steps-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .step {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    // gap: 30px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    
  }
  @media screen and (max-width: 600px) {
    .step {
      display: block; /* Change display property to block for smaller screens */
    }
  }

  .step img {
    max-width: 200px; /* Increased width */
    max-height: 100px; /* Reduced height */
    margin-right: 20px;
    margin-left: 70px;
}

  .step p {
    font-size: 1.2rem;
    color: #666;
  }
}

      
      
    .bank-details {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
      
        h2 {
          font-size: 1.5rem;
          justify-content: center;
          margin-bottom: 20px;
          font-weight: 600;
          color: #333;
        }
      
        .bank-details-grid {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 10px;
          margin-bottom: 20px;
      
          p {
            font-size: 1rem;
            margin: 0;
            color: #666;
          }
      
          .detail-label {
            font-weight: bold;
          }
        }
      
        .contact-number {
          font-size: 1rem;
          color: #666;
          margin-top: 10px;
        }
      }
      @media (max-width: 767px) {
        padding-top: 120px; /* Adjust top padding for smaller screens */
        h1 {
          font-size: 1.5rem; /* Reduce heading size for smaller screens */
        }
    
        .qr-codes-container {
          gap: 10px; /* Reduce gap between columns for smaller screens */
          .qr-code-column {
            width: 100%; /* Make columns take full width on smaller screens */
          }
        }
    
        .how-to-pay-container {
          .step {
            img {
              margin-left: 0; /* Remove left margin for images on smaller screens */
            }
          }
        }
      }
    }
  
  