// EventsParticipation.scss

.events-participation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;
    padding-top: 100px;

    .section-title {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
    }
  
    .image-container-nss-yrc{
      width: 100%;
      max-width: 400px;
  
      .image {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  
    .gallery {
      width: 100%;
  
      .gallery-title {
        font-size: 18px;
        color: #333;
        margin-bottom: 10px;
      }
    }
  
    @media screen and (min-width: 768px) {
      .events-participation-container {
        flex-direction: row;
        justify-content: space-between;
      }
  
      .nss-section,
      .yrc-section {
        width: 48%;
      }
    }
  }
  