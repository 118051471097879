.VellamalMain {
    // background-color: #dddddc;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    padding: 20px;
  
    .Vellamalmain {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 20px;
      max-width: 1200px;
      margin: 0 auto;
      padding: 20px; /* Increase padding for content spacing */
  
      .image {
        img {
          max-width: 100%;
          height: auto;
        }
      }
  
      .text-container {
        h2 {
          font-size: 1.5rem; /* Increase font size */
          border-bottom: 4px solid #1c508c; /* You can change 'red' to the color you prefer */
          display: inline;
          margin-bottom: 20px;
        }
  
        p {
          margin-top: 20px;
          line-height: 1.8; /* Increase line height */
          font-size: 1.2rem; /* Increase font size */
        }
  
        h1,
        h3,
        p {
          font-weight: 700;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        }
  
        p {
          font-family: "Open Sans", sans-serif;
        }
  
        button {
          padding: 16px 32px; /* Increase button padding */
          font-size: 1.4rem; /* Increase button font size */
          background-color: #3db71400;
          color: #1c508c;
          border-radius: 4px;
          border: 2px solid #1c508c;
          cursor: pointer;
        }
      }
    }
  
    @media screen and (min-width: 768px) {
      .Vellamalmain {
        flex-direction: row;
  
        .image {
          width: 50%;
          img {
            max-width: 100%;
            height: auto;
          }
        }
  
        .text-container {
          width: 50%;
          text-align: left;
          margin: 0;
          padding: 40px;
  
          h2 {
            font-size: 3rem; /* Increase font size */
            text-align: left;
          }
  
          p {
            text-align: left;
          }
        }
      }
    }
  }
  