// lifeAnnai.scss
.life-annai-container {
  padding-top: 100px;

  text-align: center;
  margin-bottom: 30px;
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #f7b322; // Yellow color
    }
  }

  .life-annai-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding: 20px;

    .life-annai-card {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(33.33% - 20px);
      height: 100px;
      border-radius: 8px;
      background-color: #f9f9f9;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      span {
        font-size: 16px;
        color: #333;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
