// CollegeEvents.scss

.college-events-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;
  
    .section-title {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
    }
  
    .event-gallery {
      width: 100%;
  
      .carousel-container {
        margin: 0 auto;
        max-width: 800px;
      }
    }
  
    .event-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 800px;
  
      .event-info {
        width: 100%;
        margin-bottom: 20px;
      }
  
      .event-title {
        font-size: 20px;
        color: #333;
        margin-bottom: 10px;
      }
  
      .event-description {
        font-size: 16px;
        color: #666;
        line-height: 1.6;
      }
  
      .event-metadata {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
  
      .event-date,
      .event-location {
        font-size: 14px;
        color: #888;
      }
    }
  }
  