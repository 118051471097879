.hostel-dining-container {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 40px;

  .hostel-dining-view {
    padding-top: 100px;

    display: flex;
    // width: 100%;
    // max-width: 800px;
    gap: 20px;

    .image-containerhostel {
      img {
        max-width: 80%; /* Ensure the image does not exceed its container width */
        height: auto; /* Maintain aspect ratio */
        border-radius: 8px; /* Add border radius for rounded corners */
      }/* Adjusted width for the image container */
    }

    .description-container {
      .title {
        font-size: 24px;
        color: #333;
        margin-bottom: 10px;
      }

    

      .text {
        font-size: 16px;
        color: #666;
        line-height: 1.6;
      }
    }
  }

  .gallery {
    width: 100%;

    h2 {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
    }
  }
}
