.NavBarPharmacy {
  position: fixed;
  top:4.5%;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #3EB714;
  background-image: url('../../../assets/navbarbg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  .navbarph {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-container {
      display: flex;
      align-items: center;

      .logo img {
        max-height: 80px;
        max-width: 80px;
      }

      .logo-txt h1 {
        font-size: 4rem;
        margin-top: 1px;
        color: white;
        font-weight: 800;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }

    .menu {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: white;
        font-weight: 500;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        padding: 8px 12px;
        white-space: nowrap;
        transition: all 0.3s ease;

        &.active {
          background-color: transparent;
          border-bottom: 3px solid #3EB714;
          padding-bottom: 5px;
        }
      }
    }

    .mobile-menu-icon {
      display: none;
      cursor: pointer;

      .burger {
        width: 30px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .line1, .line2, .line3 {
          width: 100%;
          height: 4px;
          background-color: white;
          transition: all 0.3s ease;
        }

        &.open {
          .line1 {
            transform: translateY(9px) rotate(45deg);
          }

          .line2 {
            opacity: 0;
          }

          .line3 {
            transform: translateY(-9px) rotate(-45deg);
          }
        }
      }
    }
  }

  .mini-navbar {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #3EB714;
    padding: 10px 0;
    text-align: center;

    a {
      display: block;
      color: white;
      padding: 10px;
      font-size: 1.2rem;
      text-decoration: none;
      transition: all 0.3s ease;

      &.active {
        border-bottom: 2px solid white;
      }
    }

    &.open {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    .navbarph {
      .logo-txt h1{
        font-size: 1.7rem !important;
      }
      .menu {
        display: none;
      }

      .mobile-menu-icon {
        display: block;
      }
    }
    
  }
}
