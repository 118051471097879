.top-nav {
  position: fixed; /* Fix the position of the navbar */
  top: 0; /* Align it to the top of the viewport */
  left: 0; /* Align it to the left edge of the viewport */
  width: 100%; /* Make it span the full width of the viewport */
  text-align: center; /* Center the list items */
  background-color: rgb(110, 29, 110);
  height: 32px;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  z-index: 1000; /* Ensure it is above other elements */

  @media (max-width: 600px) { /* Adjust this value based on your mobile breakpoint */
    display: none; /* Hide the navbar on mobile view */
  }

  ul {
    list-style-type: none; /* Remove default list styles */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    display: flex; /* Use flexbox for layout */
    gap: 60px; /* Increase the gap between list items */

    li {
      font-weight: 500;
      color: aliceblue;
      /* Removed margin to avoid conflicts with gap */
    }

    /* Add styling for the link containing the icon */
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit; /* Inherit color from parent */
      text-decoration: none; /* Remove underline */
    }

    /* Tooltip arrow styling */
    .MuiTooltip-arrow {
      color: rgb(110, 29, 110);
    }
  }
}
