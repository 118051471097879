// TestimonialsCarousel.scss

.gtco-testimonials1 {
  margin-top: 15%;

  h2 {
    display: flex;
    margin-left: 10%;
    font-size: 30px;
    font-weight: 600;
    justify-content: flex-start;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #fff;
    text-align: center;
    text-decoration: wavy;
    padding: 0;
    // margin-bottom: 50px;
  }

  h2 span {
    position: relative;
    width: 400%;
    border-bottom: 3px solid #f7b322;
  }

  h2 span:before {
    content: "";
    position: absolute;
    margin-top: 10px;
    width: 38%;
    height: 20px;
    bottom: -3px;
    left: 0;
    background-color: #f7b322;
    @media screen and (max-width: 768px) {
      width: 0;
    }
  }

  .owl-stage-outer {
    padding: 30px 0;
  }

  .owl-nav {
    display: none;
  }

  .owl-dots {
    text-align: center;

    span {
      position: relative;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: block;
      background: #fff;
      border: 2px solid #01b0f8;
      margin: 0 5px;
    }

    .active {
      box-shadow: none;

      span {
        background: #01b0f8;
        box-shadow: none;
        height: 12px;
        width: 12px;
      }
    }
  }

  .card {
    background: none;
    border: 0 !important;

    .card-img-top {
      border-radius: 5%;
      margin: 15px auto 0;
      width: 100%;
      height: auto;
      width: 250px !important;
      height: 350px !important;
    }

    h5 {
      color: white; /* Adjust the color as needed */
      font-size: 20px; /* Adjust the font size as needed */
      line-height: 1.5; /* Adjust the line height as needed */
    
      span {
        font-size: 17px; /* Adjust the font size as needed */
        color: #f3f033; /* Adjust the color as needed */
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Change font family here */
        font-weight: bold; /* Add or remove font weight as needed */
      }
    }
    
  }
}
