/* Carousel.scss */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .slick-track {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .slick-slide {
    display: flex !important; /* Ensure slides are displayed as flex containers */
    justify-content: center; /* Align items horizontally */
    align-items: center; /* Align items vertically */
    height: 300px; /* Fixed height for all slides */
    width: 100%; /* Make sure slides take up full width */
    min-width: 0; /* Ensure slides can shrink */
  }
  
  .slick-slide img {
    max-width: 100%; /* Ensure images don't exceed the slide width */
    max-height: 100%; /* Ensure images don't exceed the slide height */
    object-fit: contain; /* Maintain aspect ratio and fit image within container */
  }
  