  /* EnquiryButton.scss */

.enquiry-button {
  transform: rotate(-90deg); /* Rotate the h2 tag anti-clockwise by 90 degrees */
  gap: 3%;
  position: fixed;
  bottom: 20px; /* Adjust bottom spacing */
  top: 36%;
  right: -4%; /* Adjust right spacing */
  overflow: hidden;
  background-color: #b11010; /* Adjust color as needed */
  color: #fff; /* Text color */
  padding: 10px; /* Adjust padding as needed */
  width: 140px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px; /* Adjust font size as needed */
  cursor: pointer;
  z-index: 999; /* Ensure it's above other content */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out; /* Add smooth animation */
  @media (max-width: 768px) {

    right: -13%;
  }

  p {
    margin: 0; /* Remove default margin */
    // font-size: 18px; /* Adjust font size as needed */
    font-weight: 200;
  }

  &:hover {
    text-decoration: none; /* Remove underline on hover */
    color: #fff;
    background-color: #e71d36; /* Adjust hover background color */
    width: 130px; /* Adjust hover width */
    height: 45px; /* Adjust hover height */
  }
}