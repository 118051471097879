$primary-color: white; // Set primary font color
$footer-bg-color: rgb(72, 46, 77); // Set footer background color
$border-color: #ffffff; // Set border color
$border-radius: 10px; // Set border radius

.footer {
  background-color: $footer-bg-color;
  color: $primary-color;
  padding: 20px;

  .Footer-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .social-media-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .social-icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        svg {
          font-size: 40px; // Adjust as needed
          color: #e7e0e0; // Adjust color as needed
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .left-container,
    .centre-div,
    .right-container {
      padding-top: 20px;
    }

    .left-container {
      h4 {
        padding-bottom: 10px;
      }

      .courses-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 15px;
        .course {
          border: 1px solid white;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          padding: 3px 5px;
        }
      }
    }

    .centre-div {
      h4 {
        padding-bottom: 10px;
      }
      .quick-links-container {
        a {
          color: $primary-color; // Change font color of links
          text-decoration: none;
          &:hover {
            text-decoration: underline; // Add underline on hover
          }
        }
      }
    }

    .right-container {
      .end-headline {
        h4 {
          padding-bottom: 10px;
        }

        .contact-info {
          font-size: 14px; // Change font size of contact info

          .location,
          .phone,
          .email {
            margin-bottom: 20px; // Add margin bottom for spacing
          }

          a {
            margin-left: 8px;
            color: $primary-color; // Change font color of links
            text-decoration: none;
            &:hover {
              text-decoration: underline; // Add underline on hover
            }
          }

          .logo-container {
            img.logo {
              height: 80px;
              width: 300px;
            }
          }
        }
      }
    }
  }

  // Media Query for Small Screens
  @media (max-width: 768px) {
    .Footer-wrapper {
      .left-container,
      .centre-div,
      .right-container {
        width: 100%; // Adjust width for small screens
        max-width: none; // Remove maximum width constraint
        text-align: center; // Center content
      }
      .social-media-container {
        padding-top: 20px;
        gap: 10px;
        text-align: center;
        .social-icons {
          flex-direction: row;
        }
      }
    }
  }
}