.principal-message-container {
  padding: 40px;
  margin: 50px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.8s ease-out forwards;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .heading {
    font-size: 28px;
    color: #333333;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #333333;
      bottom: -10px;
      left: 0;
    }
  }

  .content {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px; /* Padding for the content div */
    display: flex;
    align-items: center;

    .image-container {
      flex: 1;
      margin-right: 20px;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
      }
      &:hover {
        img {
          transform: scale(1.05); /* Zoom in effect on hover */
        }
      }
    }

    .message-container {
      flex: 2;
      padding: 20px; /* Padding for smaller screens */
      transition: transform 0.3s ease-in-out; /* Smooth transition for zoom effect */

      &:hover {
        transform: scale(1.05); /* Zoom in effect on hover */
      }
      p {
        font-size: 16px;
        color: #666666;
        line-height: 1.6;
      }
    }
  }

  @media (max-width: 768px) {
    /* Apply responsive styles for screens smaller than 768px */
    .principal-message-container {
      padding: 20px; /* Reduce padding for smaller screens */
      margin: 20px auto; /* Adjust margin for smaller screens */
    }

    .heading {
      font-size: 24px; /* Reduce font size for smaller screens */
      margin-bottom: 10px; /* Reduce margin for smaller screens */
      &:after {
        bottom: -5px; /* Adjust position for smaller screens */
      }
    }
  }
}
