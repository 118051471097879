.sna-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  padding-top: 100px;
    .sna-image-container {
      max-width: 300px;
  
      .sna-image {
        width: 100%;
        border-radius: 8px;
      }
    }
  
    .sna-details {
      flex: 1;
      max-width: 500px;
  
      .sna-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }
  
      .sna-description {
        font-size: 16px;
        line-height: 1.6;
  
        p {
          margin-bottom: 10px;
        }
  
        .highlight {
          font-weight: bold;
          color: #007bff; /* Adjust color as needed */
        }
      }
    }
  }
  