.social-media-icons {
  position: fixed;
  left: 0px; /* Adjust the left offset as needed */
  top: 50%; /* Adjust the vertical position as needed */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column; /* Align icons vertically */
  gap: 5px; /* Adjust the gap between icons as needed */
  z-index: 9999;

  .social-icon {
    position: relative; /* Make the icon container relative for positioning the name */
    display: flex;
    align-items: center;
    gap: 5px; /* Adjust the gap between icon and text as needed */
    cursor: pointer; /* Add pointer cursor for better UX */
    // background-color:yellow;

    .icon {
      width: 30px; /* Adjust the icon size as needed */
      height: 30px; /* Maintain aspect ratio */
      transition: transform 0.8s ease; /* Add transition for smooth animation */
      // background-color: #007bff; /* Add background color to the icon */
      border-radius: 50%; /* Make the icon circular */
      padding: 1px;
    }

    .name {
      position: absolute; /* Position the name absolutely relative to the icon container */
      top: 50%; /* Align vertically to the middle */
      right: calc(-190% + 10px); /* Position the name to the right of the icon with margin */
      transform: translateY(-50%); /* Center vertically */
      font-size: 14px; /* Adjust the font size as needed */
      font-weight: 500;
      opacity: 0; /* Initially hide the name */
      transition: opacity 1s ease; /* Add transition for smooth opacity change */
      // background-color: #007bff; /* Add background color to the name */
      padding: 5px; /* Add padding to the name */
      border-radius: 5px; /* Add border radius to the name */
      white-space: nowrap; /* Prevent the name from wrapping */
    //  color: aliceblue;
      margin-left: -10px; /* Add margin to ensure the name is not obscured by the icon */
    }

    &:hover {
      .icon {
    background-color: white;
        
        transform: translateX(270%) rotate(360deg); /* Move to the right and rotate on hover */
      }

      .name {
        color: black;
        opacity: 1; /* Show the name on hover */
        // right: calc(100% + 10px); /* Position the name to the right of the icon with margin */
    background-color:white;
       
        // background-color: #007bff; /* Add background color to the name */
      }
    }
  }
}
