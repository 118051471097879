.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the popup is above other content */
}

.popup-content {
  // background-color: #fff; /* White background for the popup */
  padding: 20px;
  border-radius: 8px;
  max-width: 90%; /* Limit maximum width */
  max-height: 90%; /* Limit maximum height */
}

.close-btn {
  position: absolute;
  top: 52px;
  right: 30.5%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.close-btn::before,
.close-btn::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #fdfdfd;
}

.close-btn::before {
  transform: rotate(45deg);
}

.close-btn::after {
  transform: rotate(-45deg);
}

.popup-content img {
  max-width: 100%; /* Ensure the image fits within the container */
  // height: auto; /* Maintain aspect ratio */
  height: 90vh;
}

/* Hide the popup container on smaller screens */
@media screen and (max-width: 768px) {
  .popup-overlay {
    display: none;
  }
}
