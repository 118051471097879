.upcomingEvents-wrapper {
  margin-top: 10%;
  // margin-bottom: 5%;

  .upcomingEvents {
    color: white;

    .upcoming-title {
      h3 {
        margin-left: 10%;
        display: flex;
        justify-content: flex-start;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        text-decoration: wavy;
        padding: 0;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;

        span {
          position: relative;
          width: 400%;
          border-bottom: 3px solid #f7b322;

          &:before {
            content: "";
            position: absolute;
            margin-top: 10px;
            width: 36%;
            height: 20px;
            bottom: -3px;
            left: 0;
            background-color: #f7b322;

            @media (max-width: 768px) {
              width: 0; /* Set width to zero for smaller screens */
            }
          }
        }
      }
    }

    .events-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; /* Allow items to wrap */

      .notepad-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4%;
        background-image: url("../../assets/notepad.png");
        width: 80%;
        max-width: 1200px;
        height: 80vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        // border-radius: 10px;
        padding: 0;
        backdrop-filter: blur(8px);
        // margin-left: auto;
        // margin-right: auto;
        flex: 1; /* Take up remaining space */

        .scrolling-events-container {
          // margin-left: 30%;
          height: 60%;
          display: flex;
          align-items: center;
          overflow: hidden;

          @media (max-width: 768px) {
            // margin-left: 15%; /* Reduce margin left for mobile screens */
          }

          .list-view {
            // margin-top: 60%;
            flex-direction: column-reverse;
            animation: scrolling 20s linear infinite;

            ul.scrolling-events-list {
              list-style: none;
              padding: 0;
              margin: 0;

              li {
                color: black;
                margin-bottom: 20px;
                font-weight: 600;
                font-size: 1em;
                font-family: "Gill Sans", "Gill Sans MT", Calibri,
                  "Trebuchet MS", sans-serif;
              }
            }
          }
        }
      }

      .link-with-thumbnail {
        width: 100%;
        max-width: 600px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;

        .slick-slider {
          overflow: hidden;
        }

        .slick-dots {
          bottom: 10px;
        }

        .link-item {
          display: flex;
          align-items: center;
          gap: 20px;
          border-radius: 5px;
          margin: 10px;
          padding: 20px;
          opacity: 0.8;
          transition: opacity 0.5s ease-in-out;

          &:hover {
            opacity: 1;
          }

          a {
            text-decoration: none;
            color: #333;
            display: flex;
            align-items: center;
          }

          .thumbnail {
            background-color: transparent; /* Background color for thumbnail */
            padding: 10px; /* Adjust padding to separate from details */
            // border-radius: 5px; /* Rounded corners */
            border: none;
          }
      
          img {
            background-color: #e0e0e0; /* Background color for details */
            padding: 10px; /* Adjust padding to separate from thumbnail */
            border-radius: 5px; /* Rounded corners */
            max-width: 100px;
            max-height: 200px; /* Increase the max-height of the image */
            height: auto;
            margin-right: 10px;
          }
      
          .details {
            background-color: #e0e0e0; /* Background color for details */
            padding: 10px; /* Adjust padding to separate from thumbnail */
            border-radius: 5px; /* Rounded corners */
            width: 75%; /* Adjust width */
            p{
              font-size: 10px;
            }
          }
          }

          &:nth-child(even) {
            flex-direction: row-reverse;

            .thumbnail {
              margin-right: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }


@keyframes scrolling {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(-200%);
  }
}
