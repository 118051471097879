#courses-arts {
  background-image: url("../../../assets/artsandscience course background.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  .courses-wrap {
    text-align: center;

    .courses-container {
      max-width: 80%;
      background-color: #ffffff;
      padding: 20px;
      margin: 0 auto;
      text-align: left;
      padding: 40px;

      h2 {
        font-size: 3rem;
        font-weight: bold;
        background-color: rgb(184, 16, 16) !important;
        color: white;
        display: inline-block;
        padding: 10px;
        width: 100%;
        text-align: center;
      }

      p {
        margin-top: 5%;
        padding: 20px;
        font-size: 2rem;
        line-height: 1.5;
        color: #333;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 20px;
        margin: 10px 0;

        @media (max-width: 768px) {
          font-size: 1.5rem;
        }
      }

      .horizontal-dropdowns {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        
        .dropdown {
          width: 100%;
          
          .nav-link {
            font-weight: 500;
            text-decoration: none;
            font-size: 1.5rem;
            text-align: center;
            padding: 10px 15px;
            margin-bottom: 10px;
            background-color: #042243f7;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
              background-color: #6b2003;
            }

            @media (max-width: 768px) {
              font-size: 1.5rem;
            }
          }

          .dropdown-menu {
            background-color: #f5f5f5; /* Set background color for dropdown content */
            text-align: center;
            width: 100%;
            max-height: 300px;
            overflow-y: auto;
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            display: none;
            z-index: 100;
            padding: 10px; /* Add padding for dropdown content */

            li {
              list-style: none;
              padding: 10px 0;
            }

            &.open {
              display: block;
            }
          }
        }
      }
    }
  }
}
