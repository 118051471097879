.staff-gallery-container {
  padding-bottom: 70px;
  .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      font-size: 24px;
      color: #333;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
      &.slick-prev {
          left: 10px;
      }
      &.slick-next {
          right: 10px;
      }
  }
  .gallery-heading {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
      color: #333; // Set heading color
      text-transform: uppercase; // Transform text to uppercase
      font-weight: bold; // Add bold font weight
      letter-spacing: 2px; // Add letter spacing
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); // Add text shadow for depth
  }

  .slick-slide {
      padding: 0 10px;
      box-sizing: border-box;
      position: relative; /* Position relative for absolute positioning of image name */
      img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1); // Use cubic-bezier for smoother transition
      }
      .image-name {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 14px;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 5px 10px;
          border-radius: 5px;
      }
  }

  .slick-center img {
      transform: scale(1.1); // Increase size of center image
      filter: brightness(1.2); // Add highlight effect
  }

  .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      font-size: 0px; // Hide arrow text
      color: #333;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
      &.slick-prev {
          left: 10px;
      }
      &.slick-next {
          right: 10px;
      }
  }
}
