.administration-container {
  padding-top: 110px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .categories {
    width: 20%;
    // padding: 20px;
    position:fixed;
top: 25%;
    // z-index: 999;
    @media (max-width: 768px) {
      top: 7%;
      background-color: #fff;
      width: 100%;
      // padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .category {
      cursor: pointer;
      padding: 10px;
      border-bottom: 1px solid #ccc;

      &.active {
        background-color: #ddd;
      }
    }
  }

  .administration-content {
    width: 75%;
    padding: 35px;
    margin-left: 20%;
// position: relative;
    @media (max-width: 768px) {
      width: 100%;
      padding: 20px;
    margin-left: -5%;

      
    }

    h2 {
      color: #333;
      margin-bottom: 10px;
    }

    p {
      color: #666;
      font-size: medium;
      margin-bottom: 20px;
    }

    .members-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      .member {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }

        .image-container-administration {
          width: 300px;
          height: 200px;
          overflow: hidden;
          // border-radius: 50%;
          border: 3px solid #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .details {
          margin-left: 20px;

          @media (max-width: 768px) {
            margin: 10px 0 0;
            text-align: center;
          }

          h3 {
            color: #333;
            margin: 0;
          }

          p {
            color: #666;
            margin: 5px 0;
          }
        }
      }
    }
  }
}
