h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333; /* Set text color */
  text-align: center;
  margin-bottom: 20px; /* Add space below the heading */
}

.course-overview-wrap {
  // padding: 20px; /* Add padding to the wrapper */
  background-color: #f4f4f4; /* Set background color */
  // display: flex;
  flex-direction: column; /* Use flex direction column */
  align-items: center; /* Center the content */
  padding-top: 12%;
}

.breadcrumb {
  float: right;
  display: flex;
  align-items: center;
  // margin-bottom: 20px;
  font-size: 1rem;
  color: #333;
}

.content-wrapper {
  display: flex;
  align-items: flex-start; /* Align content to the top */
  width: 100%;
  max-width: 1200px; /* Set maximum width for the content */
}

.tree-view {
  width: 300px; /* Set width for the tree view */
  margin-right: 20px; /* Add space between tree view and content */
}

.component-placeholder {
  flex: 1; /* Take remaining space */
  padding: 20px; /* Add padding */
  background-color: #fff; /* Set background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.selected-component {
  /* Add any necessary styles for the selected component */
}

/* Media query for mobile view */
@media screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center align for mobile view */
  }

  .tree-view {
    width: 100%;
    margin-bottom: 20px; /* Add margin bottom for spacing */
  }

  .component-placeholder {
    width: 100%; /* Full width for mobile view */
    padding: 20px;
  }
}

.grid-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  justify-content: space-between; /* Distribute space between items */
}

.grid-container > * {
  margin-bottom: 20px; /* Add margin between grid items */
  display: inline-block; /* Arrange grid items in a row */
}
