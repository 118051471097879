/* linkWithThumbnail.scss */

.link-with-thumbnail {
  width: 200%;
  max-width: 600px;
  margin: 0 auto;

  .slick-slider {
    overflow: hidden;
  }

  .slick-vertical .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    outline: none;
    transition: opacity 0.1s ease-in-out;
  }

  .slick-dots {
    bottom: 10px;
  }

  .link-item {
   
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0.8;
    transition: opacity 0.5s ease-in-out;

    &:hover {
      opacity: 1;
    }

    a {
      text-decoration: none;
      color: #333;
    }

    .thumbnail {
      display: flex;
      align-items: center;

      img {
        max-width: 100px;
        height: auto;
        margin-right: 10px;
      }

      .details {
        h3 {
          margin: 0;
          font-size: 18px;
        }

        p {
          margin: 5px 0;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
}
